import React from 'react';
import Divider from './Divider';

export default function Section({ className, dividerTop, dividerBottom, children, ...props }) {
  return (
    <section className={(dividerTop || dividerBottom ? (className + ' has-divider') : className)} {...props}>
      {dividerTop && <Divider {...dividerTop} flip />}
      {children}
      {dividerBottom && <Divider {...dividerBottom} />}
    </section>
  );
}