import React, { useEffect, useState } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import Hero from '@components/theme/Hero';
import Section from '@components/theme/Section';
import { has } from 'lodash';
import BackgroundImage from 'gatsby-background-image';
import FrontpageLink from '@components/FrontpageLink';
import { createUtmQuery } from '@lib/searchQuery';
import ResponsiveVideo from '@components/ui/ResponsiveVideo';
import PageTitle from '@components/ui/PageTitle';
import TestimonialBlock from '@components/ui/TestimonialBlock';
import Page, { getPageElements } from '@components/Page';
import { getFirstBlock } from '@lib/blocks';

export default function VideosTemplate({ data: { testimonialsPage, videosPage, page }, pageContext: { language, refs, layouts, configuration }}) {
  const testimonials = getFirstBlock(testimonialsPage);
  const videos = getFirstBlock(videosPage);
  const elements = getPageElements(page);
  // const isSSR = typeof window === 'undefined';
  // const [heroTitle, setHeroTitle] = useState();
  // const [heroBody, setHeroBody] = useState();

  // useEffect(() => {
  //   if (!isSSR) {
  //     let pageType = new URLSearchParams(window.location.search).get('type');
  //     if (pageType) {
  //       setHeroTitle(elements[`${pageType}Title`]);
  //       setHeroBody(elements[`${pageType}Body`]);
  //     }
  //   }

  // }, []);

  return (
    <Page>
      <Hero title={elements.title} subtitle={elements.body} divider={{ variant: 3, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <Section>
        <h1 className="text-center mt-5">{videos.title}</h1>
        <div className="container py-5">
          <div className="row">
            {videos.videos.map(({ title, videoId, description }, i) =>
              <div className="col" key={i}>
                <ResponsiveVideo title={title} videoId={videoId} />
                <h2 className="text-center mt-3">{description}</h2>
              </div>
            )}
          </div>
        </div>
      </Section>
      <Section className="bg-primary-alt" dividerTop={{ variant: 4 }}>
        <h1 className="text-center mt-5">{testimonials.title}</h1>
        <div className='container py-5'>
          {testimonials.testimonials.map((testimonial, index) =>
            <TestimonialBlock key={index} testimonial={testimonial} />
          )}
        </div>
      </Section>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $videosId: String, $testimonialsId: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    testimonialsPage: wpCustomPage(id: { eq: $testimonialsId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_TestimonialsSection {
            title
            testimonials {
              name
              authorName
              authorProfession
              text
              image {
                localFile {
                  sharp: childImageSharp {
                    fixed(width: 160, height: 160) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              authorImage {
                localFile {
                  sharp: childImageSharp {
                    fixed(width: 160, height: 160) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    videosPage: wpCustomPage(id: { eq: $videosId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_VideosSection {
            title
            videos {
              title
              videoId
              description
              type
            }
          }
        }
      }
    }
  }
`;