import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

export default function Page (props) {
  return <Fragment {...props} />;
}

export const getPrefixedElements = (elements, prefixes = []) =>
  Object.keys(elements)
    .filter(key => prefixes.find(prefix => key.startsWith(prefix)))
    .reduce((result, key) => Object.assign({}, result, { [key]: elements[key] }), {});

export const getPageElements = ({ page: { htmls, strings, images }}) =>
  Object.assign({},
    ...(htmls || []).map(({ label, body }) => ({ [label]: body })),
    ...(strings || []).map(({ label, text }) => ({ [label]: text })),
    ...(images || []).map(({ label, image }) => ({ [label]: image }))
  );

export const query = graphql`
  fragment Page on WpWebsite {
    properties {
      title
      slug
    }
    page {
      htmls {
        label
        body
      }
      strings {
        label
        text
      }
      images {
        label
        image {
          localFile {
            sharp: childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;